import React from 'react';
import Typography from '@mui/material/Typography';
import {Box, Link} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const AboutMe = () => {
    return (
        <div>

            <Grid container spacing={0} sx={{mt:3}}>
                <Grid xs={12} sm={12} md={12}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1}>

                            <Grid xs={4}>
                                <Typography variant="body1"
                                            sx={{
                                                textAlign: 'left', // Align text to the left
                                                fontFamily: 'Inter', // Set the font to Inter
                                                fontSize: '18px', // Custom font size
                                                lineHeight: '18px', // Custom line height
                                                color: '#757575', // Custom color
                                                fontWeight: 400 // Custom font weight
                                            }}>
                                    <Link href="https://kaluscha.net" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit">
                                        About
                                    </Link>
                                </Typography>
                            </Grid>

                            <Grid xs={4}>
                                <Typography variant="body1"
                                            sx={{
                                                textAlign: 'center', // Align text to the left
                                                fontFamily: 'Inter', // Set the font to Inter
                                                fontSize: '18px', // Custom font size
                                                lineHeight: '18px', // Custom line height
                                                color: '#757575', // Custom color
                                                fontWeight: 400 // Custom font weight

                                            }}>
                                    <Link href="https://uptime.dnk.io/" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit">
                                        Status
                                    </Link>
                                </Typography>
                            </Grid>

                            <Grid xs={4}>
                                <Typography variant="body1"
                                            sx={{
                                                textAlign: 'right', // Align text to the left
                                                fontFamily: 'Inter', // Set the font to Inter
                                                fontSize: '18px', // Custom font size
                                                lineHeight: '18px', // Custom line height
                                                color: '#757575', // Custom color
                                                fontWeight: 400 // Custom font weight

                                            }}>
                                    <Link href="https://fm.dnk.io" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit">
                                        Radio
                                    </Link>
                                </Typography>
                            </Grid>

                            <Grid xs={4}>
                                <Typography variant="body1"
                                            sx={{
                                                textAlign: 'left', // Align text to the left
                                                fontFamily: 'Inter', // Set the font to Inter
                                                fontSize: '18px', // Custom font size
                                                lineHeight: '18px', // Custom line height
                                                color: '#757575',
                                                fontWeight: 400
                                            }}>
                                    <Link href="https://t.me/sansserif" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit">
                                        Telegram
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid xs={4}>
                                <Typography variant="body1"
                                            sx={{
                                                textAlign: 'center', // Align text to the left
                                                fontFamily: 'Inter', // Set the font to Inter
                                                fontSize: '18px', // Custom font size
                                                lineHeight: '18px', // Custom line height
                                                color: '#757575',
                                                fontWeight: 400
                                            }}>
                                    <Link href="mailto:Dominique@Kaluscha.net" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit">
                                        Email
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid xs={4}>
                                <Typography variant="body1"
                                            sx={{
                                                textAlign: 'right', // Align text to the left
                                                fontFamily: 'Inter', // Set the font to Inter
                                                fontSize: '18px', // Custom font size
                                                lineHeight: '18px', // Custom line height
                                                color: '#757575',
                                                fontWeight: 400
                                            }}>
                                    <Link href="https://linkedin.com/in/kaluscha" target="_blank" rel="noopener noreferrer" underline="hover" color="inherit">
                                        LinkedIn
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
            </Grid>

            {/*<Box sx={{ flexGrow: 1 }}>*/}
            {/*    <Grid container spacing={2}>*/}

            {/*        <Grid xs={2}>*/}
            {/*            <Typography variant="body1"*/}
            {/*                        sx={{*/}
            {/*                            textAlign: 'left', // Align text to the left*/}
            {/*                            fontFamily: 'Inter', // Set the font to Inter*/}
            {/*                            fontSize: '18px', // Custom font size*/}
            {/*                            lineHeight: '20px' // Custom line height*/}
            {/*                        }}>*/}
            {/*                <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover">*/}
            {/*                    Accenture*/}
            {/*                </Link>*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}

            {/*        <Grid xs={2}>*/}
            {/*            <Typography variant="body1"*/}
            {/*                        sx={{*/}
            {/*                            textAlign: 'left', // Align text to the left*/}
            {/*                            fontFamily: 'Inter', // Set the font to Inter*/}
            {/*                            fontSize: '18px', // Custom font size*/}
            {/*                            lineHeight: '20px' // Custom line height*/}
            {/*                        }}>*/}
            {/*                <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover">*/}
            {/*                    Accenture*/}
            {/*                </Link>*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}

            {/*        <Grid xs={2}>*/}
            {/*            <Typography variant="body1"*/}
            {/*                        sx={{*/}
            {/*                            textAlign: 'left', // Align text to the left*/}
            {/*                            fontFamily: 'Inter', // Set the font to Inter*/}
            {/*                            fontSize: '18px', // Custom font size*/}
            {/*                            lineHeight: '20px' // Custom line height*/}
            {/*                        }}>*/}
            {/*                <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover">*/}
            {/*                    Accenture*/}
            {/*                </Link>*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}

            {/*        <Grid xs={2}>*/}
            {/*            <Typography variant="body1"*/}
            {/*                        sx={{*/}
            {/*                            textAlign: 'left', // Align text to the left*/}
            {/*                            fontFamily: 'Inter', // Set the font to Inter*/}
            {/*                            fontSize: '18px', // Custom font size*/}
            {/*                            lineHeight: '20px' // Custom line height*/}
            {/*                        }}>*/}
            {/*                <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover">*/}
            {/*                    Accenture*/}
            {/*                </Link>*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid xs={2}>*/}
            {/*            <Typography variant="body1"*/}
            {/*                        sx={{*/}
            {/*                            textAlign: 'left', // Align text to the left*/}
            {/*                            fontFamily: 'Inter', // Set the font to Inter*/}
            {/*                            fontSize: '18px', // Custom font size*/}
            {/*                            lineHeight: '20px' // Custom line height*/}
            {/*                        }}>*/}
            {/*                <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover">*/}
            {/*                    Accenture*/}
            {/*                </Link>*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid xs={2}>*/}
            {/*            <Typography variant="body1"*/}
            {/*                        sx={{*/}
            {/*                            textAlign: 'left', // Align text to the left*/}
            {/*                            fontFamily: 'Inter', // Set the font to Inter*/}
            {/*                            fontSize: '18px', // Custom font size*/}
            {/*                            lineHeight: '20px' // Custom line height*/}
            {/*                        }}>*/}
            {/*                <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover">*/}
            {/*                    Accenture*/}
            {/*                </Link>*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}


        {/*<Typography variant="body1"*/}
        {/*            sx={{*/}
        {/*                textAlign: 'left', // Align text to the left*/}
        {/*                fontFamily: 'Inter', // Set the font to Inter*/}
        {/*                fontSize: '18px', // Custom font size*/}
        {/*                lineHeight: '20px' // Custom line height*/}
        {/*            }}>*/}
        {/*    I'm currently working and learning at{" "}*/}
        {/*     <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover">*/}
        {/*         Accenture*/}
        {/*    </Link>.*/}
        {/*</Typography>*/}
        </div>
    );
};

export default AboutMe;
