import React from 'react';
// import { Lottie } from 'lottie-react';
import Lottie from 'lottie-react'
import Avatar from '@mui/material/Avatar';
import lottieJson from './dnk-animated-logo.json'; // Path to your Lottie file

function LottieAvatar() {
    return (
        <Avatar sx={{ width: 30, height: 30, bgcolor: '#fff', mb:3 }} variant="rounded"> {/* Adjust size as needed */}
            <Lottie
                animationData={lottieJson}
                loop={false}
                autoplay={true}
                style={{ width: 30, height: 30 }} // Ensure the Lottie animation fits the Avatar size
            />
        </Avatar>
    );
}

export default LottieAvatar;
