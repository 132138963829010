import React from 'react';
import Typography from '@mui/material/Typography';
import {Link} from "@mui/material";

const AboutMe = () => {
    return (
        <div>
        <Typography variant="body1"
                    sx={{
                        textAlign: 'left', // Align text to the left
                        fontFamily: 'Inter', // Set the font to Inter
                        fontSize: '18px', // Custom font size
                        lineHeight: '20px' // Custom line height
                }}>
            Hi, I'm Dominique Kaluscha.
        </Typography>
        <Typography variant="body1"
                    sx={{
                        textAlign: 'left', // Align text to the left
                        fontFamily: 'Inter', // Set the font to Inter
                        fontSize: '18px', // Custom font size
                        lineHeight: '20px' // Custom line height
                    }}>
            I'm a software engineer from Zurich, Switzerland.
        </Typography>
        <Typography variant="body1"
                    sx={{
                        textAlign: 'left', // Align text to the left
                        fontFamily: 'Inter', // Set the font to Inter
                        fontSize: '18px', // Custom font size
                        lineHeight: '20px' // Custom line height

                    }}>
            I'm currently working and learning at{" "}
             <Link href="https://accenture.com" target="_blank" rel="noopener noreferrer" underline="hover"
                   sx={{
                       color: '#A100FF', // Custom color
                       fontWeight: 500 // Custom font weight

                   }}>
                 Accenture
            </Link>.
        </Typography>
        </div>
    );
};

export default AboutMe;
