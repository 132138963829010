import './App.css';
import Grid from '@mui/material/Unstable_Grid2';
import {Avatar, Box, Card, Container} from "@mui/material"; // Grid version 2
import Typography from '@mui/material/Typography';
import AboutMe from "./AboutMe";
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import Menu from "./Menu";
import LottieAvatar from "./LottieAvatar";



function App() {

  return (

    <div className="App">
      <Container maxWidth="sm" sx={{
          display: 'flex', // Set display to flex
          flexDirection: 'column', // Stack children vertically
          alignItems: 'center', // Center horizontally
          justifyContent: 'center', // Center vertically
          height: '90vh' // Adjust the height as needed
      }}>

          <Box>
            <LottieAvatar/>
            <AboutMe/>
            <Menu/>
          </Box>
      </Container>
    </div>
  );
}

export default App;
